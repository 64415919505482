import { Component, OnInit, ViewChild, HostListener, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, forkJoin, of, empty, combineLatest as observableCombineLatest, Subscription } from 'rxjs';
import { switchMap, map, mergeMap, tap } from 'rxjs/operators';
import { clone } from 'lodash';
import * as shortid from 'shortid';

// @ts-ignore
import * as pdfjs from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
import * as moment from 'moment';

import { NotificationsService, Notification, NotificationType } from '../shared/notification/notifications.service';
import { AuthenticationService } from '../shared/authentication.service';
import { UploadService } from '../shared/upload.service';
import { OcrService } from './ocr.service';
import { Ticket } from '../tickets/ticket';
import { TicketService, ReferenceEditModel } from '../tickets/ticket.service';
import { DriverReferenceService } from '../references/driver-reference/driver-reference.service';
import { JobReferenceService } from '../references/job-reference/job-reference.service';
import { TruckReferenceService } from '../references/truck-reference/truck-reference.service';
import { JobReference } from '../references/job-reference/job-reference';
import { CustomerReferenceService } from '../references/customer-reference/customer-reference.service';
import { CustomerReference } from '../references/customer-reference/customer-reference';
import { Preferences } from '../shared/preferences/preferences';
import { PreferencesService } from '../shared/preferences/preferences.service';
import { DriverReference } from '../references/driver-reference/driver-reference';
import { JobReferenceSelectorComponent } from '../references/job-reference/job-reference-selector/job-reference-selector.component';
import { CustomerReferenceSelectorComponent }
  from '../references/customer-reference/customer-reference-selector/customer-reference-selector.component';
import { DriverReferenceSelectorComponent }
  from '../references/driver-reference/driver-reference-selector/driver-reference-selector.component';
import { Attachment } from '../attachments/attachment';
import { AttachmentService } from '../attachments/attachment.service';

// constants
import { TRIPTICKETIMAGETYPE } from '../app.constants';

type ExcludedFields = (
  'id'
  | 'createdAt'
  | 'classes'
  | 'loading'
  | 'organization'
  | 'selected'
  | 'url'
  | 'filterOptions'
  | 'invoiced'
  | 'ticketNotes'
  | 'verified'
  | 'jobCode'
);
export type TicketEditModel = Pick<Ticket, Exclude<keyof Ticket, ExcludedFields>>;

/* eslint-disable @typescript-eslint/naming-convention */
export enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37
}

@Component({
  selector: 'batch-upload',
  templateUrl: './batch-upload.component.html',
  styleUrls: ['./batch-upload.component.scss'],
  providers: [
    NotificationsService,
    UploadService,
    TicketService,
    OcrService,
    DriverReferenceService,
    JobReferenceService,
    CustomerReferenceService,
    TruckReferenceService,
    PreferencesService,
  ],
})
export class BatchUploadComponent implements OnInit, OnDestroy {
  @ViewChild('jobReferenceSelector', { static: false }) jobReferenceSelector!: JobReferenceSelectorComponent;
  @ViewChild('customerReferenceSelector', { static: false }) customerReferenceSelector!: CustomerReferenceSelectorComponent;
  @ViewChild('driverReferenceSelector', { static: false }) driverReferenceSelector!: DriverReferenceSelectorComponent;

  external = false;
  contextId = '';
  dateSelected = false;

  user = this.authenticationService.user();
  callback: any;
  loader = {
    active: false,
    type: 'uploading'
  };
  uploadIndex = 0;
  preferences!: Preferences;

  policyData: any = {};

  model: TicketEditModel = { ticketDate: '' } as TicketEditModel;

  uploadFiles: any[] = [];
  uploadTotal = 0;
  savedTickets: Ticket[] = [];

  editMode!: boolean;
  viewerType!: string;
  selectedRecordIndex: any = null;
  fieldActive = false;
  attachmentsToggle: 'single' | 'multiple' = 'single';

  jobReferences$!: Observable<JobReference[]>;
  selectedJobReference!: JobReference;

  customerReferences$!: Observable<CustomerReference[]>;
  selectedCustomerReference!: CustomerReference;

  driverReferences$!: Observable<DriverReference[]>;
  selectedDriverReference!: DriverReference;

  isJobFieldVisible = false;
  isCustomerFieldVisible = false;
  isDriverFieldVisible = false;

  jobFieldsVisible = false;
  customerFieldsVisible = false;
  driverFieldsVisible = false;

  newAttachments: any[] = [];

  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public notificationsService: NotificationsService,
    private authenticationService: AuthenticationService,
    public uploadService: UploadService,
    private attachmentService: AttachmentService,
    public ticketService: TicketService,
    private preferencesService: PreferencesService
  ) { }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.selectedRecordIndex && this.savedTickets.length > 1 && !this.fieldActive) {
      if (event.keyCode === KEY_CODE.RIGHT_ARROW && this.selectedRecordIndex < (this.savedTickets.length - 1)) {
        this.nextTicket(this.savedTickets[this.selectedRecordIndex]);
      }
      if (event.keyCode === KEY_CODE.LEFT_ARROW && this.selectedRecordIndex !== 0) {
        this.previousTicket(this.savedTickets[this.selectedRecordIndex]);
      }
    }
  }

  ticketNumberVisible(): boolean {
    return (this.savedTickets.length > 0 && this.selectedRecordIndex !== null) || this.savedTickets.length === 0;
  }

  ngOnInit() {
    this.registerOrgUpdatedSubscription();
    // here we will set up listeners for any url params and set up
    // the callback_url, features, type model, and other config functions necessary
    const combinedParams = observableCombineLatest(
      [this.route.url, this.route.params, this.route.queryParams]);
    combinedParams.pipe(
      map((results) => ({url: results[0], params: results[1], qparams: results[2]}))
    ).subscribe(result => {
      if (result.qparams.tickets) {
        this.editMode = true;
        const ticketIds: string[] = result.qparams.tickets.split(',');
        this.loadTickets(ticketIds);
      } else if (result.url[1] && result.url[1].path === 'external') {
        this.external = true;
        this.contextId = result.qparams.contextId;
        if (result.qparams.date) {
          this.model.ticketDate = moment(result.qparams.date, 'YYYYMMDD');
          this.dateSelected = true;
        }
      } else {
        this.editMode = false;
      }
    });

    if (this.savedTickets.length) {
      this.preselectDropdowns(false);
    }
    this.selectedRecordIndex = this.savedTickets.length === 1 ? 0 : null;
  }

  registerOrgUpdatedSubscription() {
    this.subscriptions[0] = this.authenticationService.ticketReadOnlyAccessUpdated$.subscribe(() => {
      if (this.authenticationService.getFeature('hasReadonlyTicketAccess')) {
        this.router.navigate(['']);
      }
    });
  }

  loadTickets(ticketIds: string[]) {
    this.loader.active = true;

    const getTicketRequests: Observable<Ticket>[] = [];

    ticketIds.map(id => getTicketRequests.push(this.ticketService.get(id)));

    forkJoin(getTicketRequests).subscribe((tickets: Ticket[]) => {
      this.savedTickets = tickets;
      this.viewerType = this.savedTickets.length > 1 ? 'grid' : 'single';
      this.selectedRecordIndex = this.savedTickets.length === 1 ? 0 : null;

      if (this.savedTickets.length > 1) {
        this.preselectDropdowns(false);
      } else {
        this.preselectDropdowns(true, this.savedTickets[0]);
      }
    }, (err: any) => {
      const notification: Notification = {
        context: {
          err,
        },
        id: shortid.generate(),
        message: 'An error occured loading these selected tickets. Please refresh and try again.',
        originator: 'uploader',
        type: NotificationType.Danger,
      };

      this.notificationsService.addNotification(notification);
      throw err;
    });

    this.loader.active = false;
  }

  checkForMatchingValue(tickets: Ticket[], key: string): boolean {
    return tickets.every((ticket) => ticket[key] === tickets[0][key]);
  }

  isValidDate(input: any): boolean {
    input = new Date(input);
    return input instanceof Date && !isNaN(input.getTime());
  }

  selectedTicketLength(): number {
    let length = 0;
    this.savedTickets.forEach(ticket => length = ticket.selected ? length + 1 : length);
    return length;
  }

  getPreferences() {
    this.preferencesService.list().pipe(
      map(prefs => {
        const pref = prefs.find(p => p.name === 'uploader');
        return pref ? pref : new Preferences();
      }),
    ).subscribe(preferences => {
      this.preferences = preferences;
    });
  }

  savePreferences() {
    const preferencesObj = {
      ...this.preferences,
      type: 'uploader',
      name: 'uploader',
    };
    this.preferencesService.save(preferencesObj).subscribe();
  }

  updateTicket(ticket: Ticket): Observable<Ticket> {
    if (ticket) {
      if (this.model.customerName) {
        ticket.customerName = this.model.customerName;
      }
      if (this.model.jobName) {
        ticket.jobName = this.model.jobName;
      }
      if (this.model.ticketDate && this.isValidDate(this.model.ticketDate)) {
        ticket.ticketDate = new Date(this.model.ticketDate).toISOString().split('T')[0];
      }
      if (this.model.quantity) {
        ticket.quantity = this.model.quantity;
        ticket.quantityType = this.model.quantityType;
      }
      if (this.model.invoiceRate) {
        ticket.invoiceRate = this.model.invoiceRate;
      }
      if (this.model.material) {
        ticket.material = this.model.material;
      }
      if (this.model.carrierName) {
        ticket.carrierName = this.model.carrierName;
      }
      if (this.model.truckNumber) {
        ticket.truckNumber = this.model.truckNumber;
      }
      if (this.model.truckType) {
        ticket.truckType = this.model.truckType;
      }
      if (this.model.origin) {
        ticket.origin = this.model.origin;
      }
      if (this.model.destination) {
        ticket.destination = this.model.destination;
      }
      delete ticket.organization;
      if (!ticket.image) {
        delete ticket.image;
      }

      delete ticket.driver;
      delete ticket.truck;
      delete ticket.customer;
      delete ticket.job;

      delete ticket.jobCode;
      delete ticket.haulRate;
      // delete ticket.origin;
      // delete ticket.destination;

      return this.ticketService.removeAttachmentsAndSave(ticket).pipe(
        switchMap(ticketRes => {
          const referenceUpdates: ReferenceEditModel = { ticketId: ticketRes.id } as ReferenceEditModel;
          if (this.selectedJobReference && !!this.selectedJobReference.id) {
            referenceUpdates.job = this.selectedJobReference;
          }
          if (this.selectedCustomerReference && !!this.selectedCustomerReference.id) {
            referenceUpdates.customer = this.selectedCustomerReference;
          }
          if (this.selectedDriverReference && !!this.selectedDriverReference.id) {
            referenceUpdates.driver = this.selectedDriverReference;
          }
          if (!referenceUpdates.job && !referenceUpdates.customer && !referenceUpdates.driver) {
            return of(ticket);
          } else {
            return this.ticketService.updateFieldReferences(referenceUpdates);
          }
        }),
      );
    } else {
      return empty();
    }
  }

  onSave(attachment: Attachment, ticketIndex: number) {
    if (attachment.id) {
      this.savedTickets[ticketIndex].attachments
      = this.savedTickets[ticketIndex].attachments.map(a => a.id === attachment.id ? attachment : a);
    } else {
      this.savedTickets[ticketIndex].attachments = [attachment];
    }
  }

  onDelete(id: string, ticketIndex: number) {
    if (
      this.savedTickets[ticketIndex] &&
      this.savedTickets[ticketIndex].attachments
    ) {
      this.savedTickets[ticketIndex].attachments = this.savedTickets[
        ticketIndex
      ].attachments.filter((a) => a.id !== id);
    }
  }

  previousTicket(ticket: Ticket) {
    this.updateTicket(ticket).subscribe(() => {
      this.selectedRecordIndex = this.selectedRecordIndex - 1;
      if (this.selectedRecordIndex >= 0) {
        this.preselectDropdowns(true, this.savedTickets[this.selectedRecordIndex]);
      } else {
        this.preselectDropdowns(false);
      }
    }, (err: any) => {
      const notification: Notification = {
        context: { err },
        id: shortid.generate(),
        message: 'There was an error loading the previous ticket.',
        originator: 'uploader',
        type: NotificationType.Danger,
      };
      this.notificationsService.addNotification(notification);
      throw err;
    });
  }

  nextTicket(ticket: Ticket) {
    this.updateTicket(ticket).subscribe(() => {
      this.selectedRecordIndex = this.selectedRecordIndex + 1;
      if (this.selectedRecordIndex !== this.savedTickets.length) {
        this.preselectDropdowns(true, this.savedTickets[this.selectedRecordIndex]);
      }
    }, (err: any) => {
      const notification: Notification = {
        context: { err },
        id: shortid.generate(),
        message: 'There was an error loading the next ticket.',
        originator: 'uploader',
        type: NotificationType.Danger,
      };
      this.notificationsService.addNotification(notification);
      throw err;
    });
  }

  preselectDropdowns(single = true, ticket?: Ticket) {
    if (single && ticket) {
      const keys = Object.keys(ticket);
      for (const key of keys) {
        if (ticket[key]) {
          if (key === 'ticketDate') {
            this.model[key] = ticket[key] + 'T00:00:00';
          } else if (key === 'job' && ticket.job) {
            this.selectedJobReference = ticket.job;
            this.jobReferenceSelector.setSelectedReference(ticket.job);
          } else if (key === 'customer' && ticket.customer) {
            this.selectedCustomerReference = ticket.customer;
            this.customerReferenceSelector.setSelectedReference(ticket.customer);
          } else if (key === 'driver' && ticket.driver) {
            this.selectedDriverReference = ticket.driver;
            this.driverReferenceSelector.setSelectedReference(ticket.driver);
          } else {
            this.model[key] = ticket[key];
          }
        }
      }
    } else {
      // get our key list from the first ticket in the batch upload group
      const keys = Object.keys(this.savedTickets[0]);
      for (const key of keys) {
        if (key === 'ticketDate') {
          this.model.ticketDate = this.checkForMatchingValue(this.savedTickets, 'ticketDate') && this.savedTickets[0].ticketDate ?
                                  this.savedTickets[0].ticketDate + 'T00:00:00' : null;
        } else {
          this.model[key] = this.checkForMatchingValue(this.savedTickets, key) ? this.savedTickets[0][key] : null;
        }
      }
    }
  }

  handleMultiplePdfs = async (pdfs: any) => {
    const filePromises = pdfs.map((file: any) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = async () => {
          const pdfImages = await this.convertPDFtoImages(reader.result as ArrayBuffer);
          const attachments = pdfImages.map(pdfImg => this.getFile('', pdfImg));
          resolve(attachments);
        };
        reader.readAsArrayBuffer(file);
      });
    })
    const fileInfos = await Promise.all(filePromises);
    return fileInfos;
  }

  async fileChange(e: any, selectedRecordIndex: number) {
    const existingTicket = this.savedTickets[selectedRecordIndex];

    const uploadPdfs = async (pdfs: any[], imgFiles: any[]) => {
      if (this.attachmentsToggle === 'single' && pdfs.length > 0) {
        const splittedPdfs = await this.handleMultiplePdfs(pdfs);
        const combinedImages = [...imgFiles, ...splittedPdfs.flat()];
        if (existingTicket) {
          this.generateRecordFromImage(combinedImages as Attachment[], 0, existingTicket.id);
        } else {
          this.generateRecordFromImage(combinedImages as Attachment[]);
        }
      } else {
        pdfs.forEach((file) => {
          const reader = new FileReader();
          reader.onload = async () => {
            const pdfImages = await this.convertPDFtoImages(reader.result as ArrayBuffer);
            const attachments = pdfImages.map(pdfImg => this.getFile('', pdfImg));
            const combinedImages = [...imgFiles, ...attachments];
            combinedImages.forEach((img, index) => {
              this.generateRecordFromImage([img as Attachment], index);
            });
          };
          reader.readAsArrayBuffer(file as File);
        });
      }
    };

    if (e) {
      const uploadedFiles = Array.from(e.target.files);
      const pdfs = uploadedFiles.filter(
        (f: any) => f.type === 'application/pdf'
      );
      const images = uploadedFiles.filter(
        (f: any) => f.type !== 'application/pdf'
      );

      // if editMode === false - create ticket ; else modify
      if (this.editMode) {
        this.loader = {
          active: true,
          type: 'uploading',
        };
        const imgFiles = await Promise.all(
          Array.from(images).map(
            async (file: any) =>
              await this.readUploadedFileAsDataURL(file, [
                this.savedTickets[selectedRecordIndex].id,
              ])
          )
        );

        if (imgFiles && imgFiles.length) {
          this.saveImages(imgFiles, selectedRecordIndex);
        }
        pdfs.forEach((file) => this.convertFileToRecord(file as File));
      } else {
        // new ticket creation - if pdf and image uploaded
        if (images && images.length && pdfs.length) {
          const imgFiles = await Promise.all(
            Array.from(images).map(
              async (file: any) =>
                await this.readUploadedFileAsDataURL(file, [])
            )
          );
          uploadPdfs(pdfs, imgFiles);
        } else {         // new ticket creation - if only pdf or image uploaded
          if (images && images.length) {
            const imgFiles = await Promise.all(
              Array.from(images).map(
                async (file: any) =>
                  await this.readUploadedFileAsDataURL(file, [])
              )
            );
            if (this.attachmentsToggle === 'single') {
              if (existingTicket) {
                this.generateRecordFromImage(imgFiles as Attachment[], 0, existingTicket.id);
              } else {
                this.generateRecordFromImage(imgFiles as Attachment[] );
              }
            } else {
              imgFiles.forEach((img, index) => {
                this.generateRecordFromImage([img as Attachment], index);
              });
            }
          }
          uploadPdfs(pdfs, []);
        }
      }
    }
  }

  saveImages(files: any, ticketIndex: number) {
    this.attachmentService.saveMultipleAttachments(files, this.savedTickets[ticketIndex].id)
    .subscribe(
        (attachments: any) => {
          this.loader.active = false;
          this.savedTickets[ticketIndex].attachments = [...this.savedTickets[ticketIndex].attachments, ...attachments];
        },
        (err) => {
          this.loader.active = false;
          const notification: Notification = {
            context: { err },
            id: shortid.generate(),
            message:
              'There was an error authentication with our upload server.',
            originator: 'uploader',
            type: NotificationType.Danger,
          };
          this.notificationsService.addNotification(notification);
          throw err;
        }
      );
  }

  getFile(name: string, img: any) {
    const fileExtension = name ? name.split('.').pop() : 'png';
    return {
      file: img,
      name: `${this.uploadService.generateUUID()}.${fileExtension}`,
      file_extension: fileExtension,
      file_format: 'image',
      file_type: TRIPTICKETIMAGETYPE
    };
  }

  readUploadedFileAsDataURL = (inputFile: any, tickets: string[]) => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };

      temporaryFileReader.onload = () => {
        resolve(this.getFile(inputFile.name, temporaryFileReader.result));
      };

      temporaryFileReader.readAsDataURL(inputFile);
    });
  };

  batchRecordOperation(reset = false, close = true) {
    if (this.savedTickets.length > 1) {
      const batchUploadRequests = this.savedTickets.map(ticket => {
        ticket.quantity = this.model.quantity ? this.model.quantity : ticket.quantity;
        ticket.quantityType = this.model.quantityType ? this.model.quantityType : ticket.quantityType;

        const ticketDatetype = this.model.ticketDate ? this.model.ticketDate : ticket.ticketDate;
        if (ticketDatetype && this.isValidDate(ticketDatetype)) {
          ticket.ticketDate = new Date(ticketDatetype).toISOString().split('T')[0];
        } else {
          delete ticket.ticketDate;
        }

        ticket.truckNumber = this.model.truckNumber ? this.model.truckNumber : ticket.truckNumber;
        ticket.truckType = this.model.truckType ? this.model.truckType : ticket.truckType;
        ticket.driverName = this.model.driverName ? this.model.driverName : ticket.driverName;
        ticket.customerName = this.model.customerName ? this.model.customerName : ticket.customerName;
        ticket.jobName = this.model.jobName ? this.model.jobName : ticket.jobName;
        ticket.material = this.model.material ? this.model.material : ticket.material;
        ticket.carrierName = this.model.carrierName ? this.model.carrierName : ticket.carrierName;
        ticket.orderNumber = this.model.orderNumber ? this.model.orderNumber : ticket.orderNumber;
        ticket.invoiceRate = this.model.invoiceRate ? this.model.invoiceRate : ticket.invoiceRate;
        ticket.origin = this.model.origin ? this.model.origin : ticket.origin;
        ticket.destination = this.model.destination ? this.model.destination : ticket.destination;
        if (!ticket.image) {
          delete ticket.image;
        }
        delete ticket.organization;

        delete ticket.driver;
        delete ticket.truck;
        delete ticket.customer;
        delete ticket.job;

        delete ticket.jobCode;
        delete ticket.haulRate;
        // delete ticket.origin;
        // delete ticket.destination;

        return new Promise((resolve) => {
          this.ticketService.removeAttachmentsAndSave(ticket).pipe(
            switchMap(ticketRes => {
              const referenceUpdates: ReferenceEditModel = { ticketId: ticketRes.id } as ReferenceEditModel;
              if (this.selectedJobReference && !!this.selectedJobReference.id) {
                referenceUpdates.job = this.selectedJobReference;
              }
              if (this.selectedCustomerReference && !!this.selectedCustomerReference.id) {
                referenceUpdates.customer = this.selectedCustomerReference;
              }
              if (this.selectedDriverReference && !!this.selectedDriverReference.id) {
                referenceUpdates.driver = this.selectedDriverReference;
              }
              if (!referenceUpdates.job && !referenceUpdates.customer && !referenceUpdates.driver) {
                return of(ticket);
              } else {
                return this.ticketService.updateFieldReferences(referenceUpdates);
              }
            }),
          ).subscribe((res) => { }, err => {
            const notification: Notification = {
              context: { err },
              id: shortid.generate(),
              message: 'There was an error saving one of these tickets.' +
                       (ticket.ticketNumber ? ' (Ticket #' + ticket.ticketNumber + ')' : ''),
              originator: 'uploader',
              type: NotificationType.Danger,
            };
            this.notificationsService.addNotification(notification);
            throw err;
          }, () => resolve(true));
        });
      });
      Promise.all(batchUploadRequests).then(() => {
        if (close) {
          this.close();
        } else {
          this.viewerType = 'single';
          this.selectedRecordIndex = this.selectedRecordIndex ? this.selectedRecordIndex : 0;
          this.preselectDropdowns(true, this.savedTickets[this.selectedRecordIndex]);
        }
      });
    } else {
      const newTicketObj: any = clone(this.model);
      newTicketObj.ticketNumber = newTicketObj.ticketNumber || !this.savedTickets[this.selectedRecordIndex] ?
                                  newTicketObj.ticketNumber : this.savedTickets[this.selectedRecordIndex].ticketNumber;
      newTicketObj.ticketDate = this.model.ticketDate ?
        typeof this.model.ticketDate === 'object' ?
        new Date(this.model.ticketDate).toISOString().split('T')[0] :
        this.model.ticketDate.split('T')[0] : null;
      if (this.savedTickets && this.savedTickets.length) {
        newTicketObj.id = this.savedTickets[0].id;
        // newTicketObj.image = this.savedTickets[0].image;
      };

      delete newTicketObj.organization;

      delete newTicketObj.driver;
      delete newTicketObj.truck;
      delete newTicketObj.customer;
      delete newTicketObj.job;

      delete newTicketObj.jobCode;
      delete newTicketObj.haulRate;
      delete newTicketObj.attachments;
      delete newTicketObj.image;
      // delete newTicketObj.origin;
      // delete newTicketObj.destination;

      this.ticketService.removeAttachmentsAndSave(newTicketObj).pipe(
        switchMap(ticketRes => {
          const referenceUpdates: ReferenceEditModel = { ticketId: ticketRes.id } as ReferenceEditModel;
          if (this.selectedJobReference && !!this.selectedJobReference.id) {
            referenceUpdates.job = this.selectedJobReference;
          }
          if (this.selectedCustomerReference && !!this.selectedCustomerReference.id) {
            referenceUpdates.customer = this.selectedCustomerReference;
          }
          if (this.selectedDriverReference && !!this.selectedDriverReference.id) {
            referenceUpdates.driver = this.selectedDriverReference;
          }
          if (!referenceUpdates.job && !referenceUpdates.customer && !referenceUpdates.driver) {
            return of(ticketRes);
          } else {
            return this.ticketService.updateFieldReferences(referenceUpdates);
          }
        }),
        mergeMap((ticketRes) => forkJoin(this.newAttachments.map(a => this.attachmentService.updateAttachmentTicketId(a.id, ticketRes.id))))
      ).subscribe(() => { }, err => {
        const notification: Notification = {
          context: { err },
          id: shortid.generate(),
          message: 'There was an error saving this ticket.' +
            (newTicketObj.ticketNumber ? ' (Ticket #' + newTicketObj.ticketNumber + ')' : ''),
          originator: 'uploader',
          type: NotificationType.Danger,
        };
        this.notificationsService.addNotification(notification);
        throw err;
      }, () => {
        if (reset) {
          this.reset();
        } else {
          this.close();
        }
      });
    }
  }

  close(ticket?: Ticket) {
    if (ticket) {
      this.updateTicket(ticket).subscribe(() => {
        this.router.navigate(['tickets']);
      }, (err: any) => {
        const notification: Notification = {
          context: { err },
          id: shortid.generate(),
          message: 'There was an error saving this ticket.' + (ticket.ticketNumber ? ' (Ticket #' + ticket.ticketNumber + ')' : ''),
          originator: 'uploader',
          type: NotificationType.Danger,
        };
        this.notificationsService.addNotification(notification);
        throw err;
      });
    } else {
      this.router.navigate(['tickets']);
    }
  }

  reset() {
    this.savedTickets.length = 0;
    this.uploadFiles.length = 0;
    this.uploadTotal = 0;
    this.selectedRecordIndex = null;
    this.model = { ticketDate: '' } as TicketEditModel;
  }

  loadImage(base64Image: string) {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = base64Image;
      image.onload = () => resolve(image);
      image.onerror = (e) => reject(e);
    });
  }

  async convertPDFtoImages(pdfFile: ArrayBuffer) {
    const canvas = document.getElementById('canvas');
    const pdfImages: string[] = [];
    const scale = 2;
    const pdf = await pdfjs.getDocument(pdfFile).promise;
    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const viewport = page.getViewport({ scale });
      const newCanvas = document.createElement('canvas');
      newCanvas.setAttribute('id', `canvas-${i}`);
      canvas?.appendChild(newCanvas);

      const context = newCanvas.getContext('2d');
      newCanvas.height = viewport.height;
      newCanvas.width = viewport.width;
      if (context) {
        await page.render({ canvasContext: context, viewport }).promise;
        pdfImages.push(newCanvas.toDataURL('image/png'));
      }

    }
    return pdfImages;
  }

  convertFileToRecord(file: File, savedTicketIndex?: number) {
    const reader = new FileReader();
    reader.onload = () => this.fileLoad(reader, file, savedTicketIndex);
    reader.readAsArrayBuffer(file);
  }

  async fileLoad(reader: FileReader, file: File, savedTicketIndex?: number) {
    if (file.type.includes('pdf')) {
      const pdfImages = await this.convertPDFtoImages(reader.result as ArrayBuffer);
      this.uploadTotal = this.uploadTotal + pdfImages.length;
      this.viewerType = this.uploadTotal > 1 ? 'grid' : 'single';

      if (this.editMode) {
        const ticket = this.savedTickets[this.selectedRecordIndex];
        const attachments = pdfImages.map(pdfImg => this.getFile('', pdfImg));
        this.attachmentService.saveMultipleAttachments(attachments, ticket.id).subscribe((response) => {
          this.loader.active = false;
          const newAttachments = response as Attachment[];
          this.savedTickets[this.selectedRecordIndex].attachments = [
            ...this.savedTickets[this.selectedRecordIndex].attachments,
            ...newAttachments,
          ];
        }, () => {
          const notification: Notification = {
            context: {},
            id: shortid.generate(),
            message: 'There was an error uploading this image.',
            originator: 'uploader',
            type: NotificationType.Danger,
          };
          this.notificationsService.addNotification(notification);
        });
      } else {
        if (this.attachmentsToggle === 'single') {
          const pdfAttachments = pdfImages.map(pdfImg => this.getFile('', pdfImg));
          this.generateRecordFromImage(pdfAttachments);
        } else {
          pdfImages.forEach((pdfImg, index) => {
            const img = this.getFile('', pdfImg);
            this.generateRecordFromImage([img], index);
          });
        }
      }
    } else {
      const notification: Notification = {
        context: {},
        id: shortid.generate(),
        message: 'There was an error uploading this image. Please upload a valid image format (JPEG/PNG/GIF) or a PDF.',
        originator: 'uploader',
        type: NotificationType.Danger,
      };
      this.notificationsService.addNotification(notification);
    }
  };

  getTicketDate = (ticketDate: any): any => {
    if (!ticketDate) {
      return null;
    }
    if (moment(ticketDate).isValid()) {
      return moment(ticketDate).format('YYYY-MM-DD');
    }
    return ticketDate;
  };

  generateRecordFromImage(attachments?: any, selectedIndex = 0, ticketId?: string) {
    const ticketData = {
      ticketDate: this.getTicketDate(this.model.ticketDate),
      ticketNumber: this.model.ticketNumber ? this.model.ticketNumber : '',
      quantity: this.model.quantity ? this.model.quantity : '',
      attachments,
      ...(ticketId && {id: ticketId}),
    } as Ticket;

    this.ticketService
      .save(ticketData)
      .pipe(
        tap(ticket => {
          if (ticketId) {
            // update ticket
          } else {
            this.savedTickets.push(ticket);
          }
        }),
      )
      .subscribe(
        (createdAttachments: any) => {
          this.selectedRecordIndex = selectedIndex;
          if (ticketId) {
            this.savedTickets[selectedIndex].attachments = [...this.savedTickets[selectedIndex].attachments, ...createdAttachments];
          } else {
            if (createdAttachments.length > 0 && createdAttachments[0].tickets.length > 0) {
              const newTicketId: string = createdAttachments[0].tickets[0];
              const savedTicket = this.savedTickets.find(t => t.id === newTicketId);
              if (savedTicket) {
                savedTicket.attachments = createdAttachments;
              }
            }
          }
        },
        (err) => {
          const notification: Notification = {
            context: { err },
            id: shortid.generate(),
            message:
              'There was an error creating this ticket.' +
              (ticketData.ticketNumber
                ? ' (Ticket #' + ticketData.ticketNumber + ')'
                : ''),
            originator: 'uploader',
            type: NotificationType.Danger,
          };
          this.notificationsService.addNotification(notification);
          throw err;
        }
      );
  }

  toggleView(type: string, index?: number) {
    if (!this.external) {
      this.viewerType = type;
      if (type === 'grid') {
        this.selectedRecordIndex = null;
        this.preselectDropdowns(false);
      } else {
        this.selectedRecordIndex = index ? index : 0;
        this.batchRecordOperation(false, false);
      }
    }
  }

  onJobReferenceSelected(reference: JobReference): void {
    this.selectedJobReference = reference;
    this.isJobFieldVisible = false;
  }

  onJobReferenceBlur(): void {
    this.isJobFieldVisible = false;
  }

  onJobReferenceCreated(reference: JobReference): void {
    this.selectedJobReference = reference;
  }

  onCustomerReferenceSelected(reference: CustomerReference): void {
    this.selectedCustomerReference = reference;
    this.isCustomerFieldVisible = false;
  }

  onCustomerReferenceBlur(): void {
    this.isCustomerFieldVisible = false;
  }

  onCustomerReferenceCreated(reference: CustomerReference): void {
    this.selectedCustomerReference = reference;
  }

  onDriverReferenceSelected(reference: DriverReference): void {
    this.selectedDriverReference = reference;
    this.isDriverFieldVisible = false;
  }

  onDriverReferenceBlur(): void {
    this.isDriverFieldVisible = false;
  }

  onDriverReferenceCreated(reference: DriverReference): void {
    this.selectedDriverReference = reference;
  }

  onJobReferenceToggle(): void {
    this.jobFieldsVisible = !this.jobFieldsVisible;
  }

  onDriverReferenceToggle(): void {
    this.driverFieldsVisible = !this.driverFieldsVisible;
  }

  onCustomerReferenceToggle(): void {
    this.customerFieldsVisible = !this.customerFieldsVisible;
  }

  onJobReferenceFocused(): void {
    this.isCustomerFieldVisible = false;
    this.isDriverFieldVisible = false;
  }

  onDriverReferenceFocused(): void {
    this.isJobFieldVisible = false;
    this.isCustomerFieldVisible = false;
  }

  onCustomerReferenceFocused(): void {
    this.isJobFieldVisible = false;
    this.isDriverFieldVisible = false;
  }

  onAttachmentsToggle(e: any): void {
    this.attachmentsToggle = e.value;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub && sub.unsubscribe());
  }
}
