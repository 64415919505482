import { RouterModule, Routes } from '@angular/router';
import { BatchUploadComponent } from './upload/batch-upload.component';
import { ScaleSyncUploadComponent } from './upload/scale-sync-upload.component';

import { LoginComponent } from './login/login.component';
import { StyleguideComponent } from './styleguide/styleguide.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { SettingsComponent } from './settings/settings.component';

import { AuthGuard } from './guards/auth.guard';
import { TicketGridComponent } from './tickets/ticket-grid.component';
import { JobReferenceGridComponent } from './references/job-reference/job-reference-grid/job-reference-grid.component';
import { DriverReferenceGridComponent } from './references/driver-reference/driver-reference-grid/driver-reference-grid.component';
import { TruckReferenceGridComponent } from './references/truck-reference/truck-reference-grid/truck-reference-grid.component';
import { CustomerReferenceGridComponent } from './references/customer-reference/customer-reference-grid/customer-reference-grid.component';
import { ReadOnlyTicketsGaurd } from './guards/read-only-tickets.gaurd';

export const routes: Routes = [

  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LoginComponent, canActivate: [AuthGuard] },
  { path: 'styleguide', component: StyleguideComponent },
  { path: 'login/reset', component: PasswordResetComponent },
  // Settings
  { path: 'settings/:tab', component: SettingsComponent },
  // Tickets Table
  { path: '', component: TicketGridComponent, canActivate: [AuthGuard] },
  { path: 'tickets', component: TicketGridComponent, canActivate: [AuthGuard] },
  // Reference Tables
  { path: 'jobs', component: JobReferenceGridComponent, canActivate: [AuthGuard] },
  { path: 'drivers', component: DriverReferenceGridComponent, canActivate: [AuthGuard] },
  { path: 'trucks', component: TruckReferenceGridComponent, canActivate: [AuthGuard] },
  { path: 'customers', component: CustomerReferenceGridComponent, canActivate: [AuthGuard] },
  // Ticket Upload
  { path: 'uploader', component: BatchUploadComponent, canActivate: [AuthGuard, ReadOnlyTicketsGaurd] },
  { path: 'uploader/external', component: BatchUploadComponent },
  { path: 'scale-sync-uploader', component: ScaleSyncUploadComponent, canActivate: [AuthGuard, ReadOnlyTicketsGaurd] },
];

export const routing = RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' });
