import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, zip, Subject, from, Subscription } from 'rxjs';
import { distinctUntilChanged, debounceTime, map } from 'rxjs/operators';
import { debounce, find as _find, forEach, forOwn, has } from 'lodash';
import shortid = require('shortid');
import * as moment from 'moment';

import { AgGridAngular } from 'ag-grid-angular';
import {
  GridReadyEvent,
  ColumnApi,
  GridApi,
  ValueSetterParams,
  Column,
  ColDef,
  RowNode
} from 'ag-grid-community';
import { CellContextMenuEvent } from 'ag-grid-community/dist/lib/events';
import { SideBarDef } from 'ag-grid-community';

import { SelectAllHeaderComponent } from '../shared/grid/select-all-header.component';
import { DatepickerCellComponent } from '../shared/grid/datepicker-cell.component';
import { TicketGridDateCellComponent } from '../tickets/ticket-date-cell.component';
import { TicketGridDatetimeCellComponent } from '../tickets/ticket-datetime-cell.component';
import { AutocompleteCellComponent } from '../shared/grid/autocomplete-cell.component';
import { ImageIndicatorCellComponent } from '../shared/grid/image-indicator-cell.component';
import { CheckboxCellComponent } from '../shared/grid/checkbox-cell.component';
import { JobReferenceCellComponent } from '../references/job-reference/job-reference-cell/job-reference-cell.component';
import { CustomerReferenceCellComponent } from '../references/customer-reference/customer-reference-cell/customer-reference-cell.component';

import { GridImageToolPanelComponent } from '../shared/grid/grid-image-panel.component';
import { BulkActionDialogComponent } from '../shared/grid/bulk-action-dialog.component';

import {
  NotificationsService,
  Notification,
  NotificationType
} from '../shared/notification/notifications.service';
import { AuthenticationService } from '../shared/authentication.service';
import { ReportService } from '../reports/report/report.service';
import { Ticket } from './ticket';
import { TicketService } from './ticket.service';
import { LocationOwnerService } from '../location-owners/location-owner.service';
import { JobReferenceService } from '../references/job-reference/job-reference.service';
import { TruckReferenceService } from '../references/truck-reference/truck-reference.service';
import { Preferences } from '../shared/preferences/preferences';
import { PreferencesService } from '../shared/preferences/preferences.service';
import { DriverReferenceCellComponent } from '../references/driver-reference/driver-reference-cell/driver-reference-cell.component';
import { OcrResults } from '../upload/ocr';
import { OcrService } from '../upload/ocr.service';
import { FilterValues } from '../shared/filter';
import { TruckReferenceCellComponent } from '../references/truck-reference/truck-reference-cell/truck-reference-cell.component';
import { NoResultsComponent, ActionMenuOption, StatusCellComponent, ReviewerCellComponent } from '../shared';
import {
  ExportOption,
  ExportModalComponent
} from '../shared/export-modal/export-modal.component';
import {
  SyncOption,
  SyncModalComponent
} from '../shared/sync-modal/sync-modal.component';
import { ReadOnlyCellComponent } from '../shared/grid/readonly-cell.component';

/* eslint-disable @typescript-eslint/naming-convention */
enum ActionType {
  Export = 'Export',
  Delete = 'Delete',
  Process = 'Run Data Detection',
  Edit = 'Edit',
  TransactionProExport = 'Transaction Pro Export',
  SyncToTicketPro = 'Sync to TicketPro',
  ResetFilters = 'Reset Filters'
}

interface OcrUpdates extends OcrResults {
  id: string
  ticketDate: string
}

@Component({
  selector: 'ticket-grid',
  templateUrl: './ticket-grid.component.html',
  styleUrls: ['../../style/grid.scss', './ticket-grid.component.scss'],
  providers: [
    TicketService,
    LocationOwnerService,
    JobReferenceService,
    TruckReferenceService,
    PreferencesService,
    NotificationsService,
    OcrService
  ]
})
export class TicketGridComponent implements OnInit, OnDestroy {
  @ViewChild('ticketGrid', { static: true }) ticketGrid!: AgGridAngular;

  preferences!: Preferences;
  gridApi!: GridApi;
  columnApi!: ColumnApi;

  user = this.authenticationService.user();
  enabledFeatures: string[] = this.authenticationService.enabledFeatures() || [];
  usePandasExport = this.enabledFeatures.includes('usePandasExport');
  useLaserficheExport = this.enabledFeatures.includes('useLaserficheExport');
  transactionProTicketExportFields = this.authenticationService.getFeature('transactionProTicketExportFields');
  hasPtpTicketExportSync = this.authenticationService.getFeature('hasPtpTicketExportSync');
  hasReadOnlyTicketAccess = this.authenticationService.getFeature('hasReadonlyTicketAccess');
  allowScaleSyncUploader = this.enabledFeatures.includes('allowScaleSyncUploader') && !this.hasReadOnlyTicketAccess;
  directReferenceEditing = this.enabledFeatures.includes('directReferenceEditing');
  hasTicketQcVt = this.enabledFeatures.includes('hasTicketQcVt');
  loading = true;
  search = '';
  searchChanged: Subject<string> = new Subject<string>();
  rowModelType = 'serverSide';
  cacheBlockSize = 25;
  serverSideStoreType = 'partial';
  maxBlocksInCache = 10;

  frameworkComponents = {
    ticketImageToolPanel: GridImageToolPanelComponent,
    jobReferenceCell: JobReferenceCellComponent,
    customerReferenceCell: CustomerReferenceCellComponent,
    driverReferenceCell: DriverReferenceCellComponent,
    truckReferenceCell: TruckReferenceCellComponent,
    selectAllHeader: SelectAllHeaderComponent,
    autocompleteEditor: AutocompleteCellComponent,
    datepickerCell: DatepickerCellComponent,
    customNoRowsOverlay: NoResultsComponent,
  };

  noRowsOverlayComponent = 'customNoRowsOverlay';

  noRowsOverlayComponentParams = {
    type: 'tickets',
    onClick: () => this.openBatchUploadDialog(),
    readOnly: this.hasReadOnlyTicketAccess
  };

  statusBar = {
    statusPanels: []
  };

  sideBar: SideBarDef = {};

  defaultColDef = {
    editable: this.isCellEditable.bind(this),
    sortable: true,
    filter: 'agSetColumnFilter',
    filterParams: { newRowsAction: 'keep' },
    cellEditor: 'autocompleteEditor',
    onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e)
  };

  columnDefs: any[] = [
    {
      headerName: 'Select All',
      field: 'select',
      headerComponent: 'selectAllHeader',
      pinned: 'left',
      width: 65,
      maxWidth: 65,
      minWidth: 65,
      editable: false,
      filter: false,
      checkboxSelection: true,
      suppressMovable: true,
      suppressNavigable: true,
      suppressMenu: true,
      headerComponentParams: {
        checkboxSelection: true,
        service: this.ticketService,
        selected: this.ticketService.allSelected
      }
    },
    {
      headerName: 'Created At',
      field: 'createdAt',
      filter: 'agDateColumnFilter',
      filterParams: {
        newRowsAction: 'keep',
        buttons: ['reset']
      },
      editable: false,
      cellRendererFramework: TicketGridDatetimeCellComponent,
      width: 160,
      sortable: true
    },
    {
      headerName: 'Created By',
      field: 'createdBy',
      valueGetter: (params: any) => {
        if (!params || !params.data || !params.data.createdBy) {
          return 'Unknown';
        }

        const { firstName, lastName } = params.data.createdBy;

        return `${firstName} ${lastName}`;
      },
      cellRendererFramework: ReadOnlyCellComponent,
      editable: false,
      filter: true,
      filterParams: { buttons: ['reset'] },
      width: 200,
      sortable: true,
      cellStyle: { textAlign: 'right' }
    },
    {
      headerName: 'Source',
      field: 'ticketOrigin',
      cellRendererFramework: ReadOnlyCellComponent,
      editable: false,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any) => {
          this.ticketService
            .getValuesForFieldQuery('ticketOrigin')
            .subscribe(fields => {
              fields.push('-- Blank --');
              params.success(fields);
            });
        },
        newRowsAction: 'keep',
        buttons: ['reset']
      },
      width: 200,
      sortable: true,
      cellStyle: { textAlign: 'right' }
    },
    {
      headerName: 'Ticket Date',
      field: 'ticketDate',
      filter: 'agDateColumnFilter',
      filterParams: {
        newRowsAction: 'keep',
        buttons: ['reset']
      },
      editable: this.isCellEditable.bind(this),
      cellEditor: 'datepickerCell',
      cellEditorParams: {
        service: this.ticketService
      },
      cellRendererFramework: TicketGridDateCellComponent,
      width: 130,
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      sortable: true
    },
    {
      headerName: 'Image',
      field: 'image',
      filter: 'agSetColumnFilter',
      filterParams: {
        cellRenderer: (params: any) => {
          if (params.value === 'True') {
            return 'Missing Image';
          } else if (params.value === 'False') {
            return 'Has Image';
          } else {
            return '-- Select All --';
          }
        },
        values: ['True', 'False'],
        newRowsAction: 'keep',
        buttons: ['reset']
      },
      cellRendererFramework: ImageIndicatorCellComponent,
      cellRendererParams: {
        readOnly: this.hasReadOnlyTicketAccess
      },
      editable: false,
      width: 100,
      onCellClicked: (e: ValueSetterParams) => {
        if (!this.hasReadOnlyTicketAccess) {
          this.openImagePanel(e);
        }
      },
      sortable: true
    },
    {
      headerName: 'Verified',
      field: 'verified',
      filter: 'agSetColumnFilter',
      filterParams: {
        cellRenderer: (params: any) => {
          if (params.value === 'True') {
            return 'Verified';
          } else if (params.value === 'False') {
            return 'Unverified';
          } else {
            return '-- Select All --';
          }
        },
        values: ['True', 'False'],
        newRowsAction: 'keep',
        buttons: ['reset']
      },
      cellRendererFramework: CheckboxCellComponent,
      cellRendererParams: {
        service: this.ticketService,
        field: 'verified',
        readOnly: this.hasReadOnlyTicketAccess,
        callback: (updates: object, ticketId: string) => this.onCheckboxCellValueChange(updates, ticketId)
      },
      editable: false,
      sortable: true
    },
    {
      headerName: 'Billable',
      field: 'billable',
      filter: 'agSetColumnFilter',
      filterParams: {
        cellRenderer: (params: any) => {
          if (params.value === 'True') {
            return 'Billable';
          } else if (params.value === 'False') {
            return 'Non-billable';
          } else {
            return '-- Select All --';
          }
        },
        values: ['True', 'False'],
        newRowsAction: 'keep',
        buttons: ['reset']
      },
      cellRendererFramework: CheckboxCellComponent,
      cellRendererParams: {
        service: this.ticketService,
        field: 'billable',
        readOnly: this.hasReadOnlyTicketAccess,
        callback: (updates: object, ticketId: string) => this.onCheckboxCellValueChange(updates, ticketId)
      },
      editable: false,
      sortable: true
    },
    {
      headerName: 'Ticket #',
      field: 'ticketNumber',
      editable: this.isCellEditable.bind(this),
      filter: false,
      cellEditor: 'autocompleteEditor',
      cellEditorParams: {
        service: this.ticketService
      },
      width: 110,
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      sortable: true
    },
    {
      headerName: 'Driver',
      field: 'driverName',
      filter: 'agSetColumnFilter',
      filterParams: { buttons: ['reset'] },
      editable: this.isCellEditable.bind(this),
      cellEditor: this.directReferenceEditing ? undefined : 'driverReferenceCell',
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      width: 250,
      sortable: true
    },
    {
      headerName: 'Truck',
      field: 'truckNumber',
      filter: 'agSetColumnFilter',
      filterParams: { buttons: ['reset'] },
      editable: this.isCellEditable.bind(this),
      cellEditor: this.directReferenceEditing ? undefined : 'truckReferenceCell',
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      width: 250,
      sortable: true
    },
    {
      headerName: 'Truck #',
      field: 'truckNumber',
      filter: 'agSetColumnFilter',
      filterParams: { buttons: ['reset'] },
      editable: this.isCellEditable.bind(this),
      width: 110,
      cellEditor: 'autocompleteEditor',
      cellEditorParams: {
        service: this.ticketService
      },
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      sortable: true
    },
    {
      headerName: 'Truck Type',
      field: 'truckType',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any) => {
          this.truckReferenceService
            .getValuesForFieldQuery('value')
            .subscribe(fields => {
              fields.push('-- Blank --');
              params.success(fields);
            });
        },
        newRowsAction: 'keep',
        buttons: ['reset']
      },
      editable: this.isCellEditable.bind(this),
      width: 160,
      cellEditor: 'autocompleteEditor',
      cellEditorParams: {
        service: this.ticketService
      },
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      sortable: true
    },
    {
      headerName: 'Order #',
      field: 'orderNumber',
      filter: 'agSetColumnFilter',
      filterParams: { buttons: ['reset'] },
      editable: this.isCellEditable.bind(this),
      width: 110,
      cellEditor: 'autocompleteEditor',
      cellEditorParams: {
        service: this.ticketService
      },
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      sortable: true
    },
    {
      headerName: 'Customer',
      field: 'customerName',
      filter: 'agSetColumnFilter',
      filterParams: { buttons: ['reset'] },
      editable: this.isCellEditable.bind(this),
      cellEditor: this.directReferenceEditing ? undefined : 'customerReferenceCell',
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      width: 250,
      sortable: true
    },
    {
      headerName: 'Material',
      field: 'material',
      filter: 'agSetColumnFilter',
      filterParams: { buttons: ['reset'] },
      editable: this.isCellEditable.bind(this),
      cellEditor: 'autocompleteEditor',
      cellEditorParams: {
        service: this.ticketService
      },
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      width: 140,
      sortable: true
    },
    {
      headerName: 'Carrier Name',
      field: 'carrierName',
      filter: 'agSetColumnFilter',
      filterParams: { buttons: ['reset'] },
      editable: this.isCellEditable.bind(this),
      cellEditor: 'autocompleteEditor',
      cellEditorParams: {
        service: this.ticketService
      },
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      width: 140,
      sortable: true
    },
    {
      headerName: 'Quantity',
      field: 'quantity',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any) => {
          this.ticketService
            .getValuesForFieldQuery('quantity')
            .subscribe(fields => {
              fields.push('-- Blank --');
              params.success(fields);
            });
        },
        newRowsAction: 'keep',
        buttons: ['reset']
      },
      editable: this.isCellEditable.bind(this),
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      width: 120,
      sortable: true
    },
    {
      headerName: 'Quantity Type',
      field: 'quantityType',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any) => {
          this.ticketService
            .getValuesForFieldQuery('quantityType')
            .subscribe(fields => {
              fields.push('-- Blank --');
              params.success(fields);
            });
        },
        newRowsAction: 'keep',
        buttons: ['reset']
      },
      editable: this.isCellEditable.bind(this),
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      sortable: true
    },
    {
      headerName: 'Invoice Rate',
      field: 'invoiceRate',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any) => {
          this.ticketService
            .getValuesForFieldQuery('invoiceRate')
            .subscribe(fields => {
              fields.push('-- Blank --');
              params.success(fields);
            });
        },
        newRowsAction: 'keep',
        buttons: ['reset']
      },
      editable: this.isCellEditable.bind(this),
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      width: 120,
      sortable: true
    },
    {
      headerName: 'Rate',
      field: 'rate',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any) => {
          this.ticketService
            .getValuesForFieldQuery('rate')
            .subscribe(fields => {
              fields.push('-- Blank --');
              params.success(fields);
            });
        },
        newRowsAction: 'keep',
        buttons: ['reset']
      },
      editable: this.isCellEditable.bind(this),
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      width: 120,
      sortable: true
    },
    {
      headerName: 'Origin',
      field: 'origin',
      filter: 'agSetColumnFilter',
      filterParams: { buttons: ['reset'] },
      editable: this.isCellEditable.bind(this),
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      width: 120,
      sortable: true
    },
    {
      headerName: 'Destination',
      field: 'destination',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any) => {
          this.ticketService
            .getValuesForFieldQuery('destination')
            .subscribe(fields => {
              fields.push('-- Blank --');
              params.success(fields);
            });
        },
        newRowsAction: 'keep',
        buttons: ['reset']
      },
      editable: this.isCellEditable.bind(this),
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      width: 120,
      sortable: true
    },
    {
      headerName: 'Location Owner',
      field: 'locationOwner',
      filter: 'agSetColumnFilter',
      filterParams: { buttons: ['reset'] },
      editable: this.isCellEditable.bind(this),
      cellEditor: 'autocompleteEditor',
      cellEditorParams: {
        service: this.locationOwnerService
      },
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      width: 140,
      sortable: true
    },
    {
      headerName: 'Haul Rate',
      field: 'haulRate',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any) => {
          this.ticketService
            .getValuesForFieldQuery('haulRate')
            .subscribe(fields => {
              fields.push('-- Blank --');
              params.success(fields);
            });
        },
        newRowsAction: 'keep',
        buttons: ['reset']
      },
      editable: this.isCellEditable.bind(this),
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      width: 120,
      sortable: true
    },
    {
      headerName: 'Job',
      field: 'jobName',
      filter: 'agSetColumnFilter',
      filterParams: { buttons: ['reset'] },
      editable: this.isCellEditable.bind(this),
      cellEditor: this.directReferenceEditing ? undefined : 'jobReferenceCell',
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      width: 300,
      sortable: true
    },
    {
      headerName: 'Job Code',
      field: 'jobCode',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any) => {
          this.ticketService
            .getValuesForFieldQuery('jobCode')
            .subscribe(fields => {
              fields.push('-- Blank --');
              params.success(fields);
            });
        },
        newRowsAction: 'keep',
        buttons: ['reset']
      },
      editable: this.isCellEditable.bind(this),
      width: 500,
      cellEditor: 'autocompleteEditor',
      cellEditorParams: {
        service: this.ticketService
      },
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      sortable: true
    },
    {
      headerName: 'Checkin Kind',
      field: 'platformCheckin__kind',
      editable: false,
      filter: 'agSetColumnFilter',
      width: 160,
      sortable: true,
      filterParams: {
        values: (params: any) => {
          params.success(['isnull', 'loading', 'unloading']);
        },
        newRowsAction: 'keep',
        buttons: ['reset'],
        valueFormatter: checkinKindFilterFormatter
      }
    },
    {
      headerName: 'Invoiced',
      field: 'invoiced',
      cellRendererFramework: CheckboxCellComponent,
      cellRendererParams: {
        service: this.ticketService,
        field: 'invoiced',
        isDisabled: true,
        readOnly: this.hasReadOnlyTicketAccess,
        callback: (updates: object, ticketId: string) => this.onCheckboxCellValueChange(updates, ticketId)
      },
      editable: false,
      filter: 'agSetColumnFilter',
      filterParams: {
        cellRenderer: (params: any) => {
          if (params.value === 'True') {
            return 'Invoiced';
          } else if (params.value === 'False') {
            return 'Not Invoiced';
          } else {
            return '-- Select All --';
          }
        },
        values: ['True', 'False'],
        newRowsAction: 'keep',
        buttons: ['reset']
      },
      sortable: true
    },
    {
      headerName: 'Review Status',
      field: 'tripReviewedStatus',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any) => {
          params.success(['Approved', 'Rejected', 'Pending']);
        },
        newRowsAction: 'keep',
        buttons: ['reset']
      },
      cellRendererFramework: StatusCellComponent,
      cellRendererParams: {
        statusList: {
          pendingValue: 'pending',
          successValue: 'approved',
          failureValue: 'rejected'
        },
        readOnly: this.hasReadOnlyTicketAccess
      },
      editable: false,
      width: 150,
      sortable: true
    },
    {
      headerName: 'Reviewer',
      field: 'tripReviewedAt',
      valueGetter: (params: any) => {
        if (!params || !params.data || !params.data.tripReviewedBy) {
          return '';
        }
        const reviewer = params.data.tripReviewedBy;
        return `${reviewer.firstName} ${reviewer.lastName}`;
      },
      filter: true,
      filterParams: { buttons: ['reset'] },
      editable: false,
    },
    {
      headerName: 'Reviewed At',
      field: 'tripReviewedAt',
      filterParams: { buttons: ['reset'] },
      editable: false,
      cellRendererFramework: TicketGridDateCellComponent,
      width: 140,
      sortable: true
    },
    {
      headerName: 'Exported',
      field: 'exported',
      cellRendererFramework: CheckboxCellComponent,
      cellRendererParams: {
        service: this.ticketService,
        field: 'exported',
        isDisabled: true,
        readOnly: true
      },
      editable: false,
      filter: 'agSetColumnFilter',
      filterParams: {
        cellRenderer: (params: any) => {
          if (params.value === 'True') {
            return 'Exported';
          } else if (params.value === 'False') {
            return 'Not Exported';
          } else {
            return '-- Select All --';
          }
        },
        values: ['True', 'False'],
        newRowsAction: 'keep',
        buttons: ['reset']
      },
      sortable: false
    },
    {
      headerName: 'Ticket Notes',
      field: 'ticketNotes',
      filter: false,
      editable: this.isCellEditable.bind(this),
      width: 500,
      cellEditor: 'autocompleteEditor',
      cellEditorParams: {
        service: this.ticketService
      },
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      sortable: true
    }
  ];

  actionOptions: ActionMenuOption[] = [];

  ticketFiltersString = localStorage.getItem('ticketFilters') as string;
  ticketFilters = this.ticketFiltersString && this.ticketFiltersString.length && this.ticketFiltersString[0] === '{' ?
                  JSON.parse(this.ticketFiltersString) : {};
  private subscriptions: Subscription[] = [];
  constructor(
    private router: Router,
    public ticketService: TicketService,
    private locationOwnerService: LocationOwnerService,
    private reportService: ReportService,
    private bulkActionDialog: MatDialog,
    private exportDialog: MatDialog,
    private truckReferenceService: TruckReferenceService,
    private preferencesService: PreferencesService,
    private authenticationService: AuthenticationService,
    private notificationService: NotificationsService,
    private ocrService: OcrService,
    public snackBar: MatSnackBar
  ) {
    this.searchChanged
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(search => {
        this.loading = true;
        this.search = search;
        this.ticketService.search = this.search;
        this.refreshTable();
      });
    if (this.hasTicketQcVt) {
      this.columnDefs = this.columnDefs.concat([
        {
          headerName: 'Plant QC',
          field: 'tripPlantQc',
          valueGetter: (params: any) => {
            if (!params || !params.data || !params.data.tripPlantQc) {
              return '';
            }
            const reviewer = params.data.tripPlantQcReviewer;
            return {
              amount: params.data.tripPlantQc,
              reviewer: `${reviewer.firstName} ${reviewer.lastName}`
            };
          },
          cellRendererFramework: ReviewerCellComponent,
          editable: false,
          width: 150,
          sortable: false,
          filterParams: { buttons: ['reset'] }
        },
        {
          headerName: 'Plant VT',
          field: 'tripPlantVt',
          valueGetter: (params: any) => {
            if (!params || !params.data || !params.data.tripPlantVt) {
              return '';
            }
            const reviewer = params.data.tripPlantVtReviewer;
            return {
              amount: params.data.tripPlantVt,
              reviewer: `${reviewer.firstName} ${reviewer.lastName}`
            };
          },
          cellRendererFramework: ReviewerCellComponent,
          editable: false,
          width: 150,
          sortable: false,
          filterParams: { buttons: ['reset'] }
        },
        {
          headerName: 'Roadway QC',
          field: 'tripRoadwayQc',
          valueGetter: (params: any) => {
            if (!params || !params.data || !params.data.tripRoadwayQc) {
              return '';
            }
            const reviewer = params.data.tripRoadwayQcReviewer;
            return {
              amount: params.data.tripRoadwayQc,
              reviewer: `${reviewer.firstName} ${reviewer.lastName}`
            };
          },
          cellRendererFramework: ReviewerCellComponent,
          editable: false,
          width: 150,
          sortable: false,
          filterParams: { buttons: ['reset'] }
        },
        {
          headerName: 'Roadway VT',
          field: 'tripRoadwayVt',
          valueGetter: (params: any) => {
            if (!params || !params.data || !params.data.tripRoadwayVt) {
              return '';
            }
            const reviewer = params.data.tripRoadwayVtReviewer;
            return {
              amount: params.data.tripRoadwayVt,
              reviewer: `${reviewer.firstName} ${reviewer.lastName}`
            };
          },
          cellRendererFramework: ReviewerCellComponent,
          editable: false,
          width: 150,
          sortable: false,
          filterParams: { buttons: ['reset'] }
        }
      ]);
    }
  }

  ngOnInit() {
    this.setSideBar();
    this.registerOrgUpdatedSubscription();
  }

  setSideBar() {
    this.sideBar = {
      toolPanels: [
        {
          id: 'ticketImage',
          labelDefault: 'Ticket Image',
          labelKey: 'ticketImage',
          iconKey: 'ticket-image',
          toolPanel: 'ticketImageToolPanel',
          toolPanelParams: {
            readOnly: this.hasReadOnlyTicketAccess
          }
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true
          }
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel'
        }
      ]
    };
  }

  registerOrgUpdatedSubscription() {
    this.subscriptions[0] = this.authenticationService.ticketReadOnlyAccessUpdated$.subscribe(() => {
      this.loading = true;
      this.hasReadOnlyTicketAccess = this.authenticationService.getFeature('hasReadonlyTicketAccess');
      this.defaultColDef.editable = !this.hasReadOnlyTicketAccess;
      this.setSideBar();
      this.setActionsMenu();
      forEach(this.columnDefs, column => {
        if (column && column.cellRendererParams && has(column.cellRendererParams, 'readOnly')) {
          column.cellRendererParams.readOnly = this.hasReadOnlyTicketAccess;
        }
      });
      if (this.ticketGrid) {
        this.ticketGrid.defaultColDef = this.defaultColDef;
        this.ticketGrid.sideBar = this.sideBar;
      }
      if (this.gridApi) {
        this.gridApi.refreshCells({ force: true });
        this.gridApi.stopEditing(true);
      }
      this.loading = false;
    });
  }

  onGridReady(e: GridReadyEvent): void {
    if (!e.api || !e.columnApi) {
      return;
    }

    this.enabledFeatures = this.authenticationService.enabledFeatures() || [];

    this.gridApi = e.api;
    this.columnApi = e.columnApi;

    this.ticketGrid.defaultColDef = this.defaultColDef;
    this.ticketGrid.gridOptions.getContextMenuItems = (params: any) =>
      this.getContextMenuItems(params);
    this.ticketGrid.gridOptions.getRowNodeId = (data: any) => data.id;
    this.gridApi.addEventListener('modelUpdated', () => {
      this.loading = false;
      if (this.ticketService.allSelected) {
        this.gridApi.forEachNode(node =>
          node.setSelected(this.ticketService.allSelected)
        );
      }
    });
    this.gridApi.setServerSideDatasource(this.ticketService);
    this.setActionsMenu();
    this.applyPreferences();
    this.ticketService.getFilterValues().subscribe(filters => {
      this.setupFilters(filters);
      this.gridApi.setFilterModel(this.ticketFilters);
    });
  }

  setActionsMenu() {
    this.actionOptions = [];
    const exportOptions: ActionMenuOption[] = [];
    let exportOption = {
      name: ActionType.Export,
      onClick: () => this.onExport(),
      disabled: () => false
    };

    if (this.useLaserficheExport) {
      exportOption = {
        name: ActionType.Export,
        onClick: () => this.onLaserficheExport(),
        disabled: () => false
      };
    }

    if (this.usePandasExport) {
      exportOption = {
        name: ActionType.Export,
        onClick: () => this.onPandasExport(),
        disabled: () => false
      };
    }

    exportOptions.push(exportOption);

    if (this.transactionProTicketExportFields && this.transactionProTicketExportFields.length) {
      exportOption = {
        name: ActionType.TransactionProExport,
        onClick: () => this.onTransactionProTicketExport(),
        disabled: () => false
      };
      exportOptions.push(exportOption);
    }

    if (this.hasPtpTicketExportSync) {
      exportOption = {
        name: ActionType.SyncToTicketPro,
        onClick: () => this.onSyncToTicketPro(),
        disabled: () => false
      };
      exportOptions.push(exportOption);
    }

    this.actionOptions = [...exportOptions];
    if (!this.hasReadOnlyTicketAccess) {
      this.actionOptions.push({
        name: ActionType.Edit,
        onClick: () => this.openBatchUploadDialog(),
        disabled: () => this.numberOfTicketsSelected() === 0
      }, {
        name: ActionType.Delete,
        onClick: () => this.onDeleteTicket(),
        disabled: () => this.numberOfTicketsSelected() === 0
      }, {
        name: ActionType.Process,
        onClick: () => this.onProcessTicket(),
        disabled: () => this.numberOfTicketsSelected() === 0
      });
    }
    this.actionOptions = this.actionOptions.concat([
      {
        name: ActionType.ResetFilters,
        onClick: () => this.gridApi.setFilterModel(null),
        disabled: () => false
      }
    ]);
  }

  setupFilters(filterValues: FilterValues): void {
    (this.columnApi
      .getAllColumns() as Column[])
      .filter(column => Object.keys(filterValues).indexOf(column.getId()) > -1)
      .forEach(column => {
        const id = column.getId();
        const newColDef = column.getUserProvidedColDef() as ColDef;
        newColDef.filterParams = {
          values: filterValues[id],
          newRowsAction: 'keep'
        };
        column.setColDef(newColDef, column.getUserProvidedColDef());
      });
  }

  autoSizeAll() {
    const allColumnIds: any[] = [];

    (this.columnApi.getAllColumns() as Column[]).forEach(column => {
      allColumnIds.push(column.getColId());
    });

    this.columnApi.autoSizeColumns(allColumnIds);
  }

  getRowHeight() {
    return 48;
  };

  getContextMenuItems(params: CellContextMenuEvent) {
    const exportOptions: any[] = [];
    let exportOption = {
      name: 'Export',
      action: () => this.onExport()
    };

    if (this.useLaserficheExport) {
      exportOption = {
        name: 'Export LF',
        action: () => this.onLaserficheExport()
      };
    }

    exportOptions.push(exportOption);

    if (this.transactionProTicketExportFields && this.transactionProTicketExportFields.length) {
      exportOption = {
        name: ActionType.TransactionProExport,
        action: () => this.onTransactionProTicketExport()
      };
      exportOptions.push(exportOption);
    }
    if (this.hasPtpTicketExportSync) {
      exportOption = {
        name: ActionType.SyncToTicketPro,
        action: () => this.onSyncToTicketPro()
      };
      exportOptions.push(exportOption);
    }
    if (this.usePandasExport) {
      exportOption = {
        name: 'Export',
        action: () => this.onPandasExport()
      };
    }
    let menuOptions: any[] = [];
    if (!this.hasReadOnlyTicketAccess) {
      menuOptions = [{
        name: 'Edit',
        action: () => this.openBatchUploadDialog(params)
      }, {
        name: 'Delete',
        action: () => this.onDeleteTicket(params)
      }, {
        name: 'Run Data Detection',
        action: () => this.onProcessTicket(params)
      }];
    }
    menuOptions = menuOptions.concat([
      'copy',
      'separator',
      ...exportOptions
    ]);
    return menuOptions;
  }

  applyPreferences() {
    // Only update or create a default preference until UI for selection is in place
    this.preferencesService
      .list()
      .pipe(
        map(prefs => {
          const pref = prefs.find(p => p.name === 'tickets-default');

          return pref ? pref : new Preferences();
        })
      )
      .subscribe(
        preferences => {
          this.preferences = preferences;

          if (preferences.type === 'tickets' && preferences.blob) {
            const createdAtColumn: any = _find(preferences.blob.columnState, {colId: 'createdAt'});
            if (createdAtColumn && createdAtColumn['width'] < 160) {
              createdAtColumn['width'] = 160;
            }
            this.columnApi.setColumnState(preferences.blob.columnState);
            this.gridApi.setSortModel(preferences.blob.sortState);
          }

          const eventList = [
            'gridColumnsChanged',
            'columnPinned',
            'columnVisible',
            'columnResized',
            'columnMoved'
          ];

          eventList.forEach(event => {
            this.gridApi.addEventListener(
              event,
              debounce(() => this.savePreferences(), 300)
            );
          });
        },
        error => {
          const notification: Notification = {
            context: {
              error
            },
            id: shortid.generate(),
            message: 'An error occured saving your preferences.',
            originator: 'tickets',
            type: NotificationType.Danger
          };

          this.notificationService.addNotification(notification);
        }
      );
  }

  savePreferences() {
    const columnData = this.columnApi.getColumnState();
    const preferencesObj = {
      ...this.preferences,
      profile: this.user.id,
      type: 'tickets',
      name: 'tickets-default',
      blob: {
        columnState: columnData
      }
    };

    this.preferencesService.save(preferencesObj).subscribe(
      () => {},
      error => {
        const notification: Notification = {
          context: {
            error
          },
          id: shortid.generate(),
          message: 'An error occured saving your preferences.',
          originator: 'tickets',
          type: NotificationType.Danger
        };

        this.notificationService.addNotification(notification);
      }
    );
  }

  onExportSelected(includeImages = false) {
    const selected = this.gridApi.getSelectedRows().map((s: any) => s.id);

    this.ticketService
      .exportGrid(
        'tickets',
        selected,
        undefined,
        this.gridApi.getFilterModel(),
        this.search,
        undefined,
        includeImages
      )
      .subscribe(
        () => {
          this.openSnackBar('Export Successfully Sent to E-Mail', 'OK');
        },
        error => {
          const notification: Notification = {
            context: {
              error
            },
            id: shortid.generate(),
            message: 'An error occured exporting your data.',
            originator: 'tickets',
            type: NotificationType.Danger
          };

          this.notificationService.addNotification(notification);
        }
      );
  }

  onExportAll(includeImages = false) {
    this.ticketService
      .exportGrid(
        'tickets',
        undefined,
        undefined,
        this.gridApi.getFilterModel(),
        this.search,
        false,
        includeImages
      )
      .subscribe(
        () => {
          this.openSnackBar('Export Successfully Sent to E-Mail', 'OK');
        },
        error => {
          const notification: Notification = {
            context: {
              error
            },
            id: shortid.generate(),
            message: 'An error occured exporting your data.',
            originator: 'tickets',
            type: NotificationType.Danger
          };

          this.notificationService.addNotification(notification);
        }
      );
  }

  onExportSelectedLaserfiche() {
    const selected = this.gridApi.getSelectedRows().map((s: any) => s.id);

    this.ticketService
      .exportGridLaserfiche(
        selected,
        undefined,
        this.gridApi.getFilterModel(),
        this.search,
        this.ticketService.allSelected
      )
      .subscribe(
        () => {
          this.openSnackBar('Export Successfully Sent to E-Mail', 'OK');
        },
        error => {
          const notification: Notification = {
            context: {
              error
            },
            id: shortid.generate(),
            message: 'An error occured exporting your data.',
            originator: 'tickets',
            type: NotificationType.Danger
          };

          this.notificationService.addNotification(notification);
        }
      );
  }

  onExportAllLaserfiche() {
    this.ticketService
      .exportGridLaserfiche(
        undefined,
        undefined,
        this.gridApi.getFilterModel(),
        this.search,
        this.ticketService.allSelected
      )
      .subscribe(
        () => {
          this.openSnackBar('Export Successfully Sent to E-Mail', 'OK');
        },
        error => {
          const notification: Notification = {
            context: {
              error
            },
            id: shortid.generate(),
            message: 'An error occured exporting your data.',
            originator: 'tickets',
            type: NotificationType.Danger
          };

          this.notificationService.addNotification(notification);
        }
      );
  }

  onExportSelectedPandas() {
    const selected = this.gridApi.getSelectedRows().map((s: any) => s.id);
    const hasExportTracking = this.enabledFeatures.includes('hasExportTracking');

    this.ticketService
      .exportGridPandas(
        selected,
        undefined,
        this.gridApi.getFilterModel(),
        this.search,
        this.ticketService.allSelected,
        undefined,
        undefined,
        hasExportTracking
      )
      .subscribe(
        () => {
          this.openSnackBar('Export Successfully Sent to E-Mail', 'OK');
        },
        error => {
          const notification: Notification = {
            context: {
              error
            },
            id: shortid.generate(),
            message: 'An error occured exporting your data.',
            originator: 'tickets',
            type: NotificationType.Danger
          };

          this.notificationService.addNotification(notification);
        }
      );
  }

  onExportAllPandas() {
    const hasExportTracking = this.enabledFeatures.includes('hasExportTracking');

    this.ticketService
      .exportGridPandas(
        undefined,
        undefined,
        this.ticketFilters,
        this.search,
        this.ticketService.allSelected,
        undefined,
        undefined,
        hasExportTracking
      )
      .subscribe(
        () => {
          this.openSnackBar('Export Successfully Sent to E-Mail', 'OK');
        },
        error => {
          const notification: Notification = {
            context: {
              error
            },
            id: shortid.generate(),
            message: 'An error occured exporting your data.',
            originator: 'tickets',
            type: NotificationType.Danger
          };

          this.notificationService.addNotification(notification);
        }
      );
  }

  onExportSelectedTransactionPro() {
    const hasExportTracking = this.enabledFeatures.includes('hasExportTracking');
    const selected = this.gridApi.getSelectedRows().map((s: any) => s.id);
    this.ticketService
      .exportGridPandas(
        selected,
        undefined,
        this.gridApi.getFilterModel(),
        this.search,
        this.ticketService.allSelected,
        this.transactionProTicketExportFields,
        false,
        hasExportTracking
      )
      .subscribe(
        () => {
          this.openSnackBar('Export Successfully Sent to E-Mail', 'OK');
        },
        error => {
          const notification: Notification = {
            context: {
              error
            },
            id: shortid.generate(),
            message: 'An error occured exporting your data.',
            originator: 'tickets',
            type: NotificationType.Danger
          };

          this.notificationService.addNotification(notification);
        }
      );
  }

  onExportAllTransactionPro() {
    const hasExportTracking = this.enabledFeatures.includes('hasExportTracking');
    this.ticketService
      .exportGridPandas(
        undefined,
        undefined,
        this.gridApi.getFilterModel(),
        this.search,
        this.ticketService.allSelected,
        this.transactionProTicketExportFields,
        false,
        hasExportTracking
      )
      .subscribe(
        () => {
          this.openSnackBar('Export Successfully Sent to E-Mail', 'OK');
        },
        error => {
          const notification: Notification = {
            context: {
              error
            },
            id: shortid.generate(),
            message: 'An error occured exporting your data.',
            originator: 'tickets',
            type: NotificationType.Danger
          };

          this.notificationService.addNotification(notification);
        }
      );
  }

  onExportSelectedSyncTicketPro() {
    const selected = this.gridApi.getSelectedRows().map((s: any) => s.id);
    this.ticketService
      .syncToTicketPro(
        selected,
        undefined,
        this.gridApi.getFilterModel(),
        this.search,
        this.ticketService.allSelected,
      )
      .subscribe(
        () => {
          this.openSnackBar('Successful Sync', 'OK');
        },
        error => {
          const notification: Notification = {
            context: {
              error
            },
            id: shortid.generate(),
            message: 'An error occured during the sync.',
            originator: 'tickets',
            type: NotificationType.Danger
          };

          this.notificationService.addNotification(notification);
        }
      );
  }

  onExportAllSyncTicketPro() {
    this.ticketService
      .syncToTicketPro(
        undefined,
        undefined,
        this.gridApi.getFilterModel(),
        this.search,
        this.ticketService.allSelected,
      )
      .subscribe(
        () => {
          this.openSnackBar('Successful Sync', 'OK');
        },
        error => {
          const notification: Notification = {
            context: {
              error
            },
            id: shortid.generate(),
            message: 'An error occured during the sync.',
            originator: 'tickets',
            type: NotificationType.Danger
          };

          this.notificationService.addNotification(notification);
        }
      );
  }

  onEdit(params: CellContextMenuEvent) {
    this.gridApi.deselectAll();
    params.node.setSelected(true);
    this.openBatchUploadDialog(params);
  }

  updateRowData(ticket: Ticket) {
    const row = this.gridApi.getRowNode(ticket.id) as RowNode;
    row.setData(ticket);
  }

  onExport() {
    const selected = this.gridApi.getSelectedRows().map((s: any) => s.id);

    const dialog = this.exportDialog.open(ExportModalComponent, {
      data: {
        selected: selected && selected.length >= 1
      }
    });

    dialog.componentInstance.callback = (
      exportOption: ExportOption,
      includeImages: boolean
    ) => {
      if (exportOption === ExportOption.Selected) {
        this.onExportSelected(includeImages);
      } else {
        this.onExportAll(includeImages);
      }
    };
  }

  onLaserficheExport() {
    const selected = this.gridApi.getSelectedRows().map((s: any) => s.id);

    const dialog = this.exportDialog.open(ExportModalComponent, {
      data: {
        selected: selected && selected.length >= 1,
        imagesExportable: false
      }
    });

    dialog.componentInstance.callback = (
      exportOption: ExportOption
    ) => {
      if (exportOption === ExportOption.Selected) {
        this.onExportSelectedLaserfiche();
      } else {
        this.onExportAllLaserfiche();
      }
    };
  }

  onPandasExport() {
    const selected = this.gridApi.getSelectedRows().map((s: any) => s.id);

    const dialog = this.exportDialog.open(ExportModalComponent, {
      data: {
        selected: selected && selected.length >= 1,
        imagesExportable: false
      }
    });

    dialog.componentInstance.callback = (
      exportOption: ExportOption
    ) => {
      if (exportOption === ExportOption.Selected) {
        this.onExportSelectedPandas();
      } else {
        this.onExportAllPandas();
      }
    };
  }

  onTransactionProTicketExport() {
    const selected = this.gridApi.getSelectedRows().map((s: any) => s.id);

    const dialog = this.exportDialog.open(ExportModalComponent, {
      data: {
        selected: selected && selected.length >= 1,
        imagesExportable: false
      }
    });

    dialog.componentInstance.callback = (
      exportOption: ExportOption
    ) => {
      if (exportOption === ExportOption.Selected) {
        this.onExportSelectedTransactionPro();
      } else {
        this.onExportAllTransactionPro();
      }
    };
  }

  onSyncToTicketPro() {
    const selected = this.gridApi.getSelectedRows().map((s: any) => s.id);

    const dialog = this.exportDialog.open(SyncModalComponent, {
      data: {
        selected: selected && selected.length >= 1
      }
    });

    dialog.componentInstance.callback = (
      syncOption: SyncOption
    ) => {
      if (syncOption === SyncOption.Selected) {
        this.onExportSelectedSyncTicketPro();
      } else {
        this.onExportAllSyncTicketPro();
      }
    };
  }

  onDeleteTicket(params?: CellContextMenuEvent) {
    let selected: any;
    if (this.numberOfTicketsSelected() > 0) {
      selected = this.gridApi.getSelectedRows().map(s => s.id);
    } else {
      if (!params?.api) {
        return;
      }
      selected = params?.api?.getSelectedRows();
    }
    if (!selected || !selected.length) {
      selected = [params?.node.data];
      params?.node.setSelected(true);
    }
    const dialog = this.bulkActionDialog.open(BulkActionDialogComponent, {
      width: '500px',
      data: {
        selected
      }
    });

    dialog.componentInstance.callback = () => {
      const tickets = zip(
        ...selected.map((t: Ticket) => this.deleteTicket(t))
      );

      tickets.subscribe(
        () => {
          this.refreshTable(true);
        },
        error => {
          const notification: Notification = {
            context: {
              error
            },
            id: shortid.generate(),
            message: 'An error occured deleting your selections.',
            originator: 'tickets',
            type: NotificationType.Danger
          };

          this.notificationService.addNotification(notification);
        }
      );
    };
  }

  onProcessTicket(params?: CellContextMenuEvent) {
    let selected: any[];
    if (this.numberOfTicketsSelected() > 0) {
      selected = this.gridApi.getSelectedRows();
    } else {
      if (!params?.api) {
        return;
      }
      selected = params?.api?.getSelectedRows();
    }
    if (!selected || !selected.length) {
      selected = [params?.node.data];
      params?.node.setSelected(true);
    }
    const dialog = this.bulkActionDialog.open(BulkActionDialogComponent, {
      width: '500px',
      data: {
        selected,
        action: 'process'
      }
    });

    dialog.componentInstance.callback = () => {
      selected.forEach((ticket: Ticket) => {
        from(this.processTicket(ticket)).subscribe((result: OcrResults) => {
          const updatedColumns: string[] = [];
          const ocrTicketData: OcrUpdates = { id: ticket.id } as OcrUpdates;
          if (result.ticketNumber) {
            ocrTicketData.ticketNumber = result.ticketNumber;
            updatedColumns.push('ticketNumber');
          }
          if (result.ticketDate) {
            ocrTicketData.ticketDate = moment(result.ticketDate).format(
              'YYYY-MM-DD'
            );
            updatedColumns.push('ticketDate');
          }
          if (result.quantity) {
            ocrTicketData.quantity = result.quantity;
            updatedColumns.push('quantity');
          }
          this.saveTicket(ocrTicketData).subscribe(
            (ticketUpdates: Ticket) => {
              const row = this.gridApi.getRowNode(ticket.id) as RowNode;
              this.gridApi.flashCells({
                rowNodes: [row],
                columns: updatedColumns
              });
              row.setData(ticketUpdates);
            },
            error => {
              const notification: Notification = {
                context: {
                  error
                },
                id: shortid.generate(),
                message: 'An error occured processing your selections.',
                originator: 'tickets',
                type: NotificationType.Danger
              };
              this.notificationService.addNotification(notification);
            }
          );
        });
      });
    };
  }

  processTicket(ticket: Ticket): Promise<OcrResults> {
    if (!ticket.image) {
      return Promise.resolve({} as OcrResults);
    }
    return this.ocrService.getResults(ticket);
  }

  onCellValueChange(e: ValueSetterParams): void {
    if (e.newValue === e.oldValue) {
      return;
    }

    const ticket = e.data;

    const propertyToUpdate = {
      [e.colDef.field as string]: e.newValue,
    };
    this.ticketService.patch(propertyToUpdate, ticket.id).subscribe(
      () => {},
      (error) => {
        const notification: Notification = {
          context: {
            error,
          },
          id: shortid.generate(),
          message: 'An error occured while saving.',
          originator: 'tickets',
          type: NotificationType.Danger,
        };

        this.notificationService.addNotification(notification);
      }
    );
  }

  onCheckboxCellValueChange(updates: any, ticketId: string) {
    const ticket = this.gridApi.getRowNode(ticketId) as RowNode;
    Object.keys(updates).forEach((element: any) => {
      ticket.data[element] = updates[element];
    });
    ticket.updateData(ticket.data);
  }

  onCreateInvoice() {
    const selectedTickets: Ticket[] = this.gridApi.getSelectedRows();
    const ticketIds = selectedTickets.map(ticket => ticket.id);
    const reportData = {
      type: 'invoice',
      blob: {}
    };
    this.reportService.save(reportData).subscribe(report => {
      this.reportService.add(report.id, ticketIds).subscribe(() => {
        this.router.navigate(['invoices', report.id, 'edit']);
      });
    });
  }

  onCreateSettlement() {
    const selectedTickets: Ticket[] = this.gridApi.getSelectedRows();
    const ticketIds = selectedTickets.map(ticket => ticket.id);
    const reportData = {
      type: 'expense',
      blob: {}
    };

    this.reportService.save(reportData).subscribe(report => {
      this.reportService.add(report.id, ticketIds).subscribe(() => {
        this.router.navigate(['settlements', report.id, 'edit']);
      });
    });
  }

  openImagePanel(e: any): void {
    if (e.data.image) {
      this.gridApi.openToolPanel('ticketImage');
    }
  }

  onFirstDataRendered() {}

  changeSearch(term?: string) {
    this.searchChanged.next(term);
  }

  refreshTable(clear = false): void {
    if (clear) {
      this.gridApi.deselectAll();
      this.gridApi.clearRangeSelection();
    }
    this.gridApi.refreshCells();
    this.gridApi.purgeServerSideCache();
  }

  saveTicket(ticket: any): Observable<Ticket> {
    delete ticket.driver;
    delete ticket.organization;
    delete ticket.ticket_date_raw;
    delete ticket.truck;

    return this.ticketService.save(ticket);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, { duration: 5000 });
  }

  deleteTicket(ticket: any): Observable<any> {
    return this.ticketService.remove(ticket);
  }

  numberOfTicketsSelected(): number {
    return this.gridApi && this.gridApi.getSelectedRows().length;
  }

  openBatchUploadDialog(params?: CellContextMenuEvent) {
    const selectedTickets: Ticket[] = this.gridApi.getSelectedRows();
    if (!selectedTickets.length && params && params.node.data) {
      selectedTickets.push(params && params.node.data);
    }
    if (selectedTickets) {
      const constructedParams: any = {};
      selectedTickets.map(ticket => {
        constructedParams.tickets = constructedParams.tickets
          ? constructedParams.tickets + ',' + ticket.id
          : ticket.id;
      });
      this.router.navigate(['uploader'], { queryParams: constructedParams });
    } else {
      this.router.navigate(['uploader']);
    }
  }

  openScaleSyncUploadDialog() {
    this.router.navigate(['scale-sync-uploader']);
  }

  addRow() {
    this.saveTicket({}).subscribe(() => {
      const sort = [{ colId: 'createdAt', sort: 'desc' }];
      this.gridApi.setSortModel(sort);
      this.refreshTable();
    });
  }

  isExternalFilterPresent() {}

  doesExternalFilterPass() {}

  onFilterChanges() {
    this.ticketFilters = this.gridApi.getFilterModel();
    const filterKeys = Object.keys(this.ticketFilters);
    forEach(filterKeys, (key) => {
      const obj = this.ticketFilters[key];
      if(!obj.values || !obj.values.length) {
        delete this.ticketFilters[key];
      }
    });
    localStorage.setItem('ticketFilters', JSON.stringify(this.ticketFilters));
  }

  isCellEditable() {
    return !this.hasReadOnlyTicketAccess;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub && sub.unsubscribe());
  }

}

const checkinKindFilterFormatter = (params: any) => {
  if (params.value === 'isnull') {
    return '-- Blank --';
  } else {
    return params.value;
  }
};
